import React from "react";
import { Redirect } from "react-router-dom";

import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import AddUser from "../pages/Admin/ManageAgent/AddUser";
import UserList from "../pages/Admin/ManageAgent/UserList";
import EditUser from "../pages/Admin/ManageAgent/EditUser";
import UserDetails from "../pages/Admin/ManageAgent/UserDetails";
import WelcomeLetter from "../pages/Admin/ManageAgent/WelcomeLetter";
import ResetLoginDetails from "../pages/Admin/ManageAgent/ResetLoginDetails";
import UserProfile from "../pages/Admin/ManageAgent/UserProfile";
import AddPackage from "../pages/Admin/CommercialPackage/AddPackage";
import PackageList from "../pages/Admin/CommercialPackage/PackageList";
import EditPackage from "../pages/Admin/CommercialPackage/EditPackage";
import AddNewInvestment from "../pages/Admin/Investment/AddNewInvestment";
import InvestmentHistory from "../pages/Admin/Investment/InvestmentHistory";
import PendingInvtRequest from "../pages/Admin/Investment/PendingInvtRequest";
import Logout from "../pages/Admin/Logout/Logout";
import UserListTreeView from "../pages/Admin/ManageAgent/UserListTreeView";
import ActiveInvestments from "../pages/Admin/ManageAgent/ActiveInvestments";
import WithdrawalHistory from "../pages/Admin/WithdrawalModule/WithdrawalHistory";
import PendingWithdrawalRequest from "../pages/Admin/WithdrawalModule/PendingWithdrawalRequest";
import CreditTransactionHistory from "../pages/Admin/CreditModule/CreditTransactionHistory";
import AddCreditTransaction from "../pages/Admin/CreditModule/AddCreditTransaction";
import TeamInvestmentHistory from "../pages/Admin/Investment/TeamInvestmentHistory";
import EditInvestment from "../pages/Admin/Investment/EditInvestment";
import DbBackup from "../pages/Admin/DbBackup/DbBackup";
import InvestmentReceipt from "../pages/Admin/Investment/InvestmentReceipt";
import AddTransactionCommissionWallet from "../pages/Admin/CommissionWallet/AddTransaction";
import ManageCronJob from "../pages/Admin/Settings/ManageCronJob";
import XlsCommissionBalance from "../pages/Admin/Reports/XlsCommissionBal";
import AddNotice from "../pages/Admin/ManageNotice/AddNotice";
import EditNotice from "../pages/Admin/ManageNotice/EditNotice";
import NoticeList from "../pages/Admin/ManageNotice/NoticeList";
import AddExpense from "../pages/Admin/ExpenseModule/AddExpense";
import EditExpense from "../pages/Admin/ExpenseModule/EditExpense";
import ExpenseHistory from "../pages/Admin/ExpenseModule/ExpenseHistory";
import SendWpMessage from "../pages/Admin/ManageAgent/SendWpMessage";
import AddCashDeposit from "../pages/Admin/AdminCashDeposits/AddCashDeposit";
import EditCashDeposit from "../pages/Admin/AdminCashDeposits/EditCashDeposit";
import CashDepositHistory from "../pages/Admin/AdminCashDeposits/CashDepositHistory";
import CashDepositReceipt from "../pages/Admin/AdminCashDeposits/CashDepositReceipt";
import AddPayment from "../pages/Admin/PaymentModule/AddPayment";
import PaymentHistory from "../pages/Admin/PaymentModule/PaymentHistory";
import EditPayment from "../pages/Admin/PaymentModule/EditPayment";
import ActivityLogs from "../pages/Admin/Reports/ActivityLogs";
import CommissionToInvestment from "../pages/Admin/Investment/CommissionToInvestment";

const adminAuthProtectedRoutes = [
  { path: "/admin/dashboard", component: Dashboard },
  { path: "/admin/add_user", component: AddUser },
  { path: "/admin/user_list", component: UserList },
  { path: "/admin/user_list_tree_view", component: UserListTreeView },
  { path: "/admin/edit_user/:userId", component: EditUser },
  { path: "/admin/user_details/:userId", component: UserDetails },
  { path: "/admin/welcome_letter/:userId", component: WelcomeLetter },
  { path: "/admin/reset_login_details/:userId", component: ResetLoginDetails },
  { path: "/admin/user_profile/:userId", component: UserProfile },
  { path: "/admin/active_investments/:id", component: ActiveInvestments },
  { path: "/admin/add_package", component: AddPackage },
  { path: "/admin/package_list", component: PackageList },
  { path: "/admin/edit_package/:id", component: EditPackage },

  { path: "/admin/add_new_investment", component: AddNewInvestment },
  {
    path: "/admin/commission_to_investment",
    component: CommissionToInvestment,
  },
  { path: "/admin/investment_history", component: InvestmentHistory },
  { path: "/admin/investment_receipt/:id", component: InvestmentReceipt },
  {
    path: "/admin/team_investment_history",
    component: TeamInvestmentHistory,
  },
  { path: "/admin/pending_invt_request", component: PendingInvtRequest },
  { path: "/admin/withdrawal_history", component: WithdrawalHistory },
  {
    path: "/admin/pending_withdrawal_request",
    component: PendingWithdrawalRequest,
  },
  {
    path: "/admin/credit_transaction_history",
    component: CreditTransactionHistory,
  },
  {
    path: "/admin/add_credit_transaction",
    component: AddCreditTransaction,
  },
  {
    path: "/admin/edit_investment/:id",
    component: EditInvestment,
  },
  {
    path: "/admin/add_txn_commission_wallet/:id",
    component: AddTransactionCommissionWallet,
  },
  { path: "/admin/db_backup", component: DbBackup },
  { path: "/admin/manage_cron_job", component: ManageCronJob },
  { path: "/admin/xls_commission_balance", component: XlsCommissionBalance },
  { path: "/admin/activity_logs", component: ActivityLogs },

  { path: "/admin/add_notice", component: AddNotice },
  { path: "/admin/notice_list", component: NoticeList },
  { path: "/admin/edit_notice/:id", component: EditNotice },

  { path: "/admin/add_expense", component: AddExpense },
  { path: "/admin/expense_history", component: ExpenseHistory },
  { path: "/admin/edit_expense/:id", component: EditExpense },

  { path: "/admin/add_cash_deposit", component: AddCashDeposit },
  { path: "/admin/cash_deposit_history", component: CashDepositHistory },
  { path: "/admin/edit_cash_deposit/:id", component: EditCashDeposit },
  { path: "/admin/cash_deposit_receipt/:id", component: CashDepositReceipt },

  { path: "/admin/add_payment", component: AddPayment },
  { path: "/admin/payment_history", component: PaymentHistory },
  { path: "/admin/edit_payment/:id", component: EditPayment },

  { path: "/admin/send_wp_message/:id", component: SendWpMessage },

  { path: "/admin/logout", component: Logout },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/admin_login" />,
  },
];

export { adminAuthProtectedRoutes };
