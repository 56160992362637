import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { ToWords } from "to-words";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const InvtTransferDialog = (props) => {
  const row = props.selectedData;

  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const userIdRef = useRef();

  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [data, setData] = useState();

  const fetchHandler = async () => {
    const userId = userIdRef.current.value;

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    loadingCtx.startLoading();

    props.handleClose();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setOpen(true);
        setData(responseData.data.result);
        setId(responseData.data.result.id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    props.handleOpen();

    loadingCtx.stopLoading();
  };

  const [amount, setAmount] = useState();
  const remarkRef = useRef();

  const transferHandler = async () => {
    const remark = remarkRef.current.value;

    if (!id) {
      Swal.fire("Opps!", "Invalid User", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    //   props.handleClose();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.transfer(
        token,
        row.order_id,
        id,
        amount,
        remark
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        await props.fetchData();
        Swal.fire("Success!", message, "success");
      } else {
        //  props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      // props.handleOpen();
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Investment Transfer</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style={{ margin: "15px" }}>
            {row && (
              <ul class="list-group list-group-unbordered mb-3">
                <li class="list-group-item">
                  <b>User ID</b>{" "}
                  <span class="float-right">
                    {row.tbl_user ? row.tbl_user.user_id : ""} (
                    {row.tbl_user ? row.tbl_user.name : ""})
                  </span>
                </li>

                <li class="list-group-item">
                  <b>Order ID</b>{" "}
                  <span class="float-right">{row.order_id}</span>
                </li>

                <li class="list-group-item">
                  <b>Amount</b>{" "}
                  <span class="float-right">
                    <span
                      class="badge badge-pill badge-primary"
                      style={{ fontSize: "13px" }}
                    >
                      ₹{row.amount}
                    </span>
                  </span>
                </li>

                <li class="list-group-item">
                  <b>Avl Bal</b>{" "}
                  <span class="float-right">
                    <span
                      class="badge badge-pill badge-success"
                      style={{ fontSize: "13px" }}
                    >
                      ₹
                      {parseFloat(row.amount - row.amount_withdrawn).toFixed(2)}
                    </span>
                  </span>
                </li>
              </ul>
            )}

            <form ref={formRef} autoComplete="off">
              <div style={{ margin: "15px", paddingTop: "15px" }}>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">
                    Transfer User ID
                  </label>
                  <div class="col-sm-9">
                    <div class="input-group input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Transfer User ID"
                        ref={userIdRef}
                      />
                      <span class="input-group-append">
                        <button
                          type="button"
                          class="btn btn-info btn-flat"
                          onClick={fetchHandler}
                        >
                          Go
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                {open && data && (
                  <div>
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Name</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value={data.name + " (" + data.user_id + ")"}
                          readOnly
                        />
                      </div>
                    </div>

                    <div class="form-group row" style={{ paddingTop: "15px" }}>
                      <label class="col-sm-3 col-form-label">Amount</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          placeholder="Amount"
                          maxLength={10}
                          style={{ fontWeight: "bold" }}
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            fontSize: "16px",
                          }}
                        >
                          {toWords.convert(amount ? amount : "0")}
                        </p>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Remark</label>
                      <div class="col-sm-9">
                        <textarea
                          ref={remarkRef}
                          className="form-control"
                          placeholder="Remark"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>

            <button
              type="button"
              class="btn btn-primary"
              onClick={transferHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InvtTransferDialog;
