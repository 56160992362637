import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccountCircleIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { retry } from "redux-saga/effects";

const UserList = () => {
  const loadingCtx = useContext(LoadingContext);

  // const [fromDate, setFromDate] = useState(
  //   new Date().toISOString().slice(0, 10)
  // );
  // const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [userType, setUserType] = useState();
  const [clientType, setClientType] = useState();

  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [rank, setRank] = useState();
  const [balanceFilter, setBalanceFilter] = useState();
  const [paymentDate, setPaymentDate] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchUsers();
    getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // [page, size, searchText, status, rank, balanceFilter]

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    setUserType(_userType);
  };

  const fetchUsers = async () => {
    // if (userType !== "Admin") {
    //   alert("Access denided");
    //   return;
    // }
    const token = AuthService.getAccessToken();

    setLoading(true);

    AgentsService.fetchAgents(
      token,
      page,
      size,
      searchText,
      clientType,
      status,
      rank,
      balanceFilter,
      paymentDate
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedData, setSelectedData] = useState();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewTeamLeader = async (leader_id) => {
    if (!leader_id) {
      Swal.fire("Opps!", "Empty Leader ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, leader_id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const name = responseData.data.result.name;
        Swal.fire("Success!", name, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const adminColumns = [
    {
      name: "Team Leader",
      cell: (row) => (
        <div
          style={{ marginTop: "10px", marginBottom: "10px" }}
          onClick={() => {
            handleViewTeamLeader(row.leader_id);
          }}
        >
          View
        </div>
      ),
    },
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.user_type !== "User") {
              return <span>{row.user_id + " (" + row.user_type + ")"}</span>;
            } else {
              return <span>{row.user_id}</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {row.name}

            {(() => {
              if (row.rank === "1 STAR") {
                return <span>⭐</span>;
              } else if (row.rank === "2 STAR") {
                return <span>⭐⭐</span>;
              } else if (row.rank === "3 STAR") {
                return <span>⭐⭐⭐</span>;
              } else if (row.rank === "4 STAR") {
                return <span>⭐⭐⭐⭐</span>;
              } else if (row.rank === "5 STAR") {
                return <span>⭐⭐⭐⭐⭐</span>;
              }
            })()}
          </span>
        </div>
      ),
    },
    {
      name: "Mobile",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.mobile}</span>
        </div>
      ),
    },
    {
      name: "Balance",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            Inv Bal:{" "}
            <span
              style={{ color: "green", fontWeight: "bold", fontSize: "14px" }}
            >
              ₹{row.tbl_wallet_balance.main_wallet}
            </span>
          </span>{" "}
          <br />
          <span>
            Comm Bal:{" "}
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}
            >
              ₹{row.tbl_wallet_balance.commission_wallet}
            </span>
          </span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_date}</span>
        </div>
      ),
    },
    {
      name: "Active Investments",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            className="badge badge-primary"
            onClick={() => {
              window.open("/admin/active_investments/" + row.id, "_blank");
              return true;
            }}
          >
            View
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "Active") {
              return (
                <span class="badge badge-pill badge-success">{row.status}</span>
              );
            } else if (row.status === "Inactive") {
              return (
                <span class="badge badge-pill badge-danger">{row.status}</span>
              );
            } else {
              return (
                <span class="badge badge-pill badge-warning">{row.status}</span>
              );
            }
          })()}

          {(() => {
            if (row.is_signup === "YES") {
              return <small>...</small>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              window.open(
                "/admin/user_details/" + row.user_id,
                "",
                "width=740,height=650,scrollbars=yes,resizable=1"
              );

              //history.push("/user_details/" + row.user_id);
              return true;
            }}
          >
            <VisibilityIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/admin/user_profile/" + row.user_id);
            }}
          >
            <AccountCircleIcon />
          </IconButton>
        </div>
      ),
    },
    {
      name: "More Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => {
              handleClick(event);
              setSelectedData(row);
            }}
          >
            <ArrowDropDownCircleIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* {userType === "Admin" && ( */}
            <MenuItem
              onClick={() => {
                autoLoginHandler(selectedData.user_id);
              }}
            >
              Login to User Panel
            </MenuItem>
            {/* )} */}

            <MenuItem
              onClick={() => {
                window.open(
                  "/admin/welcome_letter/" + selectedData.user_id,
                  "",
                  "width=950,height=650,scrollbars=yes,resizable=no,toolbar=yes,directories=no,location=no,menubar=no,status=no,left=50,top=0"
                );
              }}
            >
              Welcome Letter
            </MenuItem>

            <MenuItem
              onClick={() => {
                window.open(
                  "/admin/user_details/" + selectedData.user_id,
                  "_blank"
                );
              }}
            >
              Print Form
            </MenuItem>

            {userType === "Admin" && (
              <MenuItem
                onClick={() => {
                  history.push(
                    "/admin/reset_login_details/" + selectedData.user_id
                  );
                }}
              >
                Reset Login Details
              </MenuItem>
            )}

            {userType === "Admin" && (
              <MenuItem
                onClick={() => {
                  history.push("/admin/send_wp_message/" + selectedData.id);
                }}
              >
                Send Whatsapp Msg
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                history.push(
                  "/admin/add_txn_commission_wallet/" + selectedData.user_id
                );
              }}
            >
              Add TXN - Commission Wallet
            </MenuItem>

            {userType === "Admin" && (
              <MenuItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure to delete?",
                    showCancelButton: true,
                    confirmButtonText: `Delete`,
                    confirmButtonColor: "red",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteUserHandler(selectedData.id);
                    }
                  });
                }}
              >
                Delete User
              </MenuItem>
            )}
          </Menu>
        </div>
      ),
      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  const empColumns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.user_type !== "User") {
              return <span>{row.user_id + " (" + row.user_type + ")"}</span>;
            } else {
              return <span>{row.user_id}</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {row.name}

            {(() => {
              if (row.rank === "1 STAR") {
                return <span>⭐</span>;
              } else if (row.rank === "2 STAR") {
                return <span>⭐⭐</span>;
              } else if (row.rank === "3 STAR") {
                return <span>⭐⭐⭐</span>;
              } else if (row.rank === "4 STAR") {
                return <span>⭐⭐⭐⭐</span>;
              } else if (row.rank === "5 STAR") {
                return <span>⭐⭐⭐⭐⭐</span>;
              }
            })()}
          </span>
        </div>
      ),
    },
  ];

  const autoLoginHandler = async (userId) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.autoLogin(token, userId);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const accessToken = responseData.data.access_token;
        const user = responseData.data.user;

        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("user", user);

        window.open("/user/dashboard", "_blank", "");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const deleteUserHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.deleteUser(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const xlsReport = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.xlsReport(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="User List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">User List</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="form-group row">
                              <div class="col-md-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>

                              <div class="col-md-3">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setClientType(e.target.value);
                                  }}
                                >
                                  <option value="">Select Client Type</option>
                                  <option defaultValue="Remisier">
                                    Remisier
                                  </option>
                                  <option defaultValue="Investor">
                                    Investor
                                  </option>
                                </select>
                              </div>

                              <div class="col-md-3">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option defaultValue="Active">Active</option>
                                  <option defaultValue="Inactive">
                                    Inactive
                                  </option>
                                  <option defaultValue="Pending">
                                    Pending
                                  </option>
                                </select>
                              </div>

                              <div class="col-md-3">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setRank(e.target.value);
                                  }}
                                >
                                  <option value="">Select Rank</option>
                                  <option defaultValue="1 START">1 STAR</option>
                                  <option defaultValue="2 START">2 STAR</option>
                                  <option defaultValue="3 START">3 STAR</option>
                                  <option defaultValue="4 START">4 STAR</option>
                                  <option defaultValue="5 START">5 STAR</option>
                                </select>
                              </div>
                            </div>

                            <div className="row">
                              <div class="col-md-3">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setBalanceFilter(e.target.value);
                                  }}
                                >
                                  <option value="">
                                    ---Select Balance Filter---
                                  </option>
                                  <option value="1">
                                    User List Having Balance
                                  </option>
                                  <option value="2">
                                    User List Having Zero Balance
                                  </option>
                                </select>
                              </div>

                              <div class="col-md-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Payment Date"
                                  onChange={(e) => {
                                    setPaymentDate(e.target.value);
                                  }}
                                />
                              </div>

                              <div class="col-md-3 ">
                                <button
                                  type="button"
                                  className="form-control btn-primary"
                                  onClick={() => {
                                    fetchUsers();
                                  }}
                                >
                                  Search
                                </button>
                              </div>
                              <div class="col-sm-3">
                                <div>
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary btn-icon waves-effect waves-light"
                                    onClick={xlsReport}
                                  >
                                    <i class="fa fa-download"></i> EXPORT
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={userType === "Admin" ? adminColumns : empColumns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);

                        fetchUsers();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default UserList;
