import common from "../../utill/Common";

class CashDepositService {
  delete = async (token, id) => {
    const url = common.baseUrl + "admin/admin_cash_deposit/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  add = async (token, amount, payment_date, remark) => {
    const url = common.baseUrl + "admin/admin_cash_deposit/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        amount,
        payment_date,
        remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (token, id, amount, payment_date, remark) => {
    const url = common.baseUrl + "admin/admin_cash_deposit/edit";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        amount,
        payment_date,
        remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "admin/admin_cash_deposit/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  history = async (token, from_date, to_date, search_text, page, size) => {
    const url = common.baseUrl + "admin/admin_cash_deposit/history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CashDepositService();
