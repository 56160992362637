import React from "react";
import { Redirect } from "react-router-dom";
import { adminAuthProtectedRoutes } from "./AdminRoutes";

import AdminLogin from "../pages/Admin/Login/Login";
import Login from "../pages/User/Login/Login";
import ForgotPassword from "../pages/User/ForgotPassword/ForgotPassword";

import Dashboard from "../pages/User/Dashboard/Dashboard";
import ChangePassword from "../pages/User/Profile/ChangePassword";
import Logout from "../pages/User/Logout/Logout";
import ResetPin from "../pages/User/Profile/ResetPin";
import MyProfile from "../pages/User/Profile/MyProfile";
import AddUser from "../pages/User/ManageUser/AddUser";
import UserListTreeView from "../pages/User/ManageUser/UserListTreeView";
import UserList from "../pages/User/ManageUser/UserList";
import NewInvestmentRequest from "../pages/User/InvestmentModule/NewInvestmentRequest";
import InvestmentRequestHistory from "../pages/User/InvestmentModule/InvestmentRequestHistory";
import IncomeSummary from "../pages/User/IncomeReport/IncomeSummary";
import InvestmentHistory from "../pages/User/InvestmentModule/InvestmentHistory";
import NewWithdrawalRequest from "../pages/User/WithdrawalModule/NewWithdrawalRequest";
import WithdrawalHistory from "../pages/User/WithdrawalModule/WithdrawalHistory";
import UserListSso from "../pages/Sso/UserListSso";
import UserListTreeViewSso from "../pages/User/ManageUser/UserListTreeViewSso";
import TeamInvestmentHistory from "../pages/User/InvestmentModule/TeamInvestmentHistory";
import CommissionHistory from "../pages/User/IncomeReport/CommissionHistory";
import UserSignup from "../pages/Admin/UserSignUp/UserSignUp";
import DownlineWithdrawalRequests from "../pages/User/WithdrawalModule/DownlineWithdrawalRequests";

const authProtectedRoutes = [
  { path: "/user/dashboard", component: Dashboard },
  { path: "/user/change_password", component: ChangePassword },
  { path: "/user/reset_pin", component: ResetPin },
  { path: "/user/my_profile", component: MyProfile },
  { path: "/user/add_user", component: AddUser },
  { path: "/user/user_list", component: UserList },
  { path: "/user/user_list_tree_view", component: UserListTreeView },
  { path: "/user/new_investment_request", component: NewInvestmentRequest },
  {
    path: "/user/investment_request_history",
    component: InvestmentRequestHistory,
  },
  {
    path: "/user/investment_history",
    component: InvestmentHistory,
  },
  {
    path: "/user/team_investment_history",
    component: TeamInvestmentHistory,
  },
  {
    path: "/user/income_summary",
    component: IncomeSummary,
  },
  {
    path: "/user/commission_history",
    component: CommissionHistory,
  },
  {
    path: "/user/new_withdrawal_request",
    component: NewWithdrawalRequest,
  },
  {
    path: "/user/withdrawal_history",
    component: WithdrawalHistory,
  },
  {
    path: "/user/downline_withdrawal_requests",
    component: DownlineWithdrawalRequests,
  },
  { path: "/user/logout", component: Logout },
  { path: "/sso/user_list", component: UserListSso },
  { path: "/sso/user_list_tree_view", component: UserListTreeViewSso },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/user_signup", component: UserSignup },
  { path: "/forgot_password", component: ForgotPassword },
  { path: "/admin_login", component: AdminLogin },
];

export { adminAuthProtectedRoutes, authProtectedRoutes, publicRoutes };
