import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { ToWords } from "to-words";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import DataTableComponent from "react-data-table-component";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import PaymentService from "../../../services/Admin/PaymentService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const PaymentsArrayDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);
  const data = props.paymentsArray;

  const getPaymentReceiptLink = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.viewPaymentReceipt(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        window.open(responseData.data.url, "_blank");

        //Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const columns = [
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Bank Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_bank ? row.tbl_bank.bank_name : "null"}</span>
        </div>
      ),
    },
    {
      name: "Payment Mode",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontWeight: "bold" }}>{row.payment_mode}</span> <br />
          <span>{row.payment_date}</span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "green" }}
          >
            ₹{row.amount}
          </span>
          <br />
          <span style={{ fontWeight: "bold", fontSize: "14px", color: "red" }}>
            {toWords.convert(row.amount)}
          </span>
        </div>
      ),
    },
    {
      name: "Payment REF ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_ref_id}</span>
        </div>
      ),
    },
    {
      name: "Linked Invt Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.linked_invt_order_id}</span>
        </div>
      ),
    },
    {
      name: "Timestamp",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              getPaymentReceiptLink(row.id);
            }}
          >
            <DownloadIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content" style={{ width: "100%" }}>
          <div class="modal-header">
            <h4 class="modal-title">Payments Array</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <DataTableComponent
              noHeader={true}
              columns={columns}
              data={data}
              // progressPending={loading}
              highlightOnHover
            />
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentsArrayDialog;
