import React, { useRef, useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import SignupService from "../../../services/User/SignupService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";

const UserSignup = () => {
  const loadingCtx = useContext(LoadingContext);

  //   React.useEffect(() => {
  //     getUserType();
  //     fetchUserList();
  //     fetchPackageList();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  const [token, setToken] = useState();
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState();
  const [state, setState] = useState("");

  const [gender, setGender] = useState("");
  const [nomineeRelation, setNomineeRelation] = useState("");
  const [accountType, setAccountType] = useState("");

  const [location, setLocation] = useState();
  const [introVerifyStatus, setIntroVerifyStatus] = useState();
  const [introName, setIntroName] = useState();

  const [mobileOtp, setMobileOtp] = useState();

  const formRef = useRef();
  const introIdRef = useRef();
  const panNumberRef = useRef();
  const nameRef = useRef();
  const dobRef = useRef();
  const fathersNameRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();
  const pincodeRef = useRef();
  const cityRef = useRef();
  const districtRef = useRef();
  const addressRef = useRef();
  const beneficiaryNameRef = useRef();
  const bankNameRef = useRef();
  const accountNumberRef = useRef();
  const ifscCodeRef = useRef();
  const nomineeNameRef = useRef();

  const passwordRef = useRef();
  const pinRef = useRef();

  const addUserHandler = async () => {
    const introId = introIdRef.current.value;
    const panNumber = panNumberRef.current.value;
    const name = nameRef.current.value;
    const dob = dobRef.current.value;
    const fathersName = fathersNameRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;
    const pincode = pincodeRef.current.value;
    const city = cityRef.current.value;
    const district = districtRef.current.value;
    const address = addressRef.current.value;
    const nomineeName = nomineeNameRef.current.value;
    const beneficiaryName = beneficiaryNameRef.current.value;
    const bankName = bankNameRef.current.value;
    const accountNumber = accountNumberRef.current.value;
    const ifscCode = ifscCodeRef.current.value;
    const password = passwordRef.current.value;
    const pin = pinRef.current.value;

    if (!introId) {
      Swal.fire("Opps!", "Invalid Introducer ID / Mobile", "error");
      return;
    }

    if (!introVerifyStatus) {
      Swal.fire(
        "Opps!",
        "Please verify the introducer before submitting the form.",
        "error"
      );
      return;
    }

    if (!panNumber) {
      Swal.fire("Opps!", "Invalid PAN Number", "error");
      return;
    }

    if (!title) {
      Swal.fire("Opps!", "Invalid Title", "error");
      return;
    }

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    // if (!dob) {
    //   Swal.fire("Opps!", "Invalid Date of Birth", "error");
    //   return;
    // }

    if (!gender) {
      Swal.fire("Opps!", "Invalid Gender", "error");
      return;
    }

    // if (!fathersName) {
    //   Swal.fire("Opps!", "Invalid Fathers Name", "error");
    //   return;
    // }

    if (!mobile || mobile.length < 10 || mobile.length > 10) {
      Swal.fire("Opps!", "Invalid Mobile Number", "error");
      return;
    }

    // if (!email) {
    //   Swal.fire("Opps!", "Invalid Email ID", "error");
    //   return;
    // }

    // if (!pincode) {
    //   Swal.fire("Opps!", "Invalid PIN Code", "error");
    //   return;
    // }

    // if (!state) {
    //   Swal.fire("Opps!", "Invalid State", "error");
    //   return;
    // }

    // if (!city) {
    //   Swal.fire("Opps!", "Invalid City", "error");
    //   return;
    // }

    // if (!district) {
    //   Swal.fire("Opps!", "Invalid District", "error");
    //   return;
    // }

    // if (!address) {
    //   Swal.fire("Opps!", "Invalid Address", "error");
    //   return;
    // }

    // if (!nomineeName) {
    //   Swal.fire("Opps!", "Invalid Nominee Name", "error");
    //   return;
    // }

    // if (!nomineeRelation) {
    //   Swal.fire("Opps!", "Invalid Nominee Relation", "error");
    //   return;
    // }

    // if (!beneficiaryName) {
    //   Swal.fire("Opps!", "Invalid Beneficiary Name", "error");
    //   return;
    // }

    // if (!bankName) {
    //   Swal.fire("Opps!", "Invalid Bank Name", "error");
    //   return;
    // }

    // if (!accountNumber) {
    //   Swal.fire("Opps!", "Invalid Account Number", "error");
    //   return;
    // }

    // if (!ifscCode) {
    //   Swal.fire("Opps!", "Invalid IFSC Code", "error");
    //   return;
    // }

    // if (!accountType) {
    //   Swal.fire("Opps!", "Invalid Account Type", "error");
    //   return;
    // }

    if (!password) {
      Swal.fire("Opps!", "Invalid Password", "error");
      return;
    }

    if (password.length < 6 || password.length > 15) {
      Swal.fire(
        "Opps!",
        "Password length should be between 6-15 characters",
        "error"
      );
      return;
    }

    if (!pin) {
      Swal.fire("Opps!", "Invalid PIN", "error");
      return;
    }

    if (pin.length < 4 || pin.length > 4) {
      Swal.fire("Opps!", "PIN length should be 4 digit", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await SignupService.sendOtp(mobile, email);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setMessage(responseData.message);
        setToken(responseData.data.token);
        setOpen(true);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const verifyOtpHandler = async () => {
    const introId = introIdRef.current.value;
    const panNumber = panNumberRef.current.value;
    const name = nameRef.current.value;
    const dob = dobRef.current.value;
    const fathersName = fathersNameRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;
    const pincode = pincodeRef.current.value;
    const city = cityRef.current.value;
    const district = districtRef.current.value;
    const address = addressRef.current.value;
    const nomineeName = nomineeNameRef.current.value;
    const beneficiaryName = beneficiaryNameRef.current.value;
    const bankName = bankNameRef.current.value;
    const accountNumber = accountNumberRef.current.value;
    const ifscCode = ifscCodeRef.current.value;
    const password = passwordRef.current.value;
    const pin = pinRef.current.value;

    if (!token) {
      Swal.fire("Opps!", "Invalid Token", "error");
      return;
    }

    if (!mobileOtp) {
      Swal.fire("Opps!", "Invalid Mobile OTP", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await SignupService.verifyOtp(
        token,
        mobileOtp,
        introId,
        panNumber,
        title,
        name,
        dob,
        gender,
        fathersName,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
        nomineeName,
        nomineeRelation,
        beneficiaryName,
        bankName,
        accountNumber,
        ifscCode,
        accountType,
        password,
        pin
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        setOpen(false);
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const verifyIntro = async () => {
    const introId = introIdRef.current.value;

    if (!introId) {
      Swal.fire("Opps!", "Invalid Introducer ID / Mobile", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await SignupService.verifyIntro(introId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setIntroName(responseData.data.name);
        setIntroVerifyStatus(true);

        Swal.fire(
          "Success!",
          `Introducer Verified Successfully [ Name: ${responseData.data.name} ]`,
          "success"
        );
      } else {
        setIntroVerifyStatus(false);
        setIntroName("");
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const getLocationHandler = async (pincode) => {
    if (pincode.length < 6) {
      return;
    }

    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.getLocation(token, pincode);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setLocation(responseData.data);
        setState(responseData.data ? responseData.data[0]["Circle"] : "");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  window.onload = function () {
    document.body.className = "";
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <section class="content">
        <div class="container-fluid" style={{ padding: "50px" }}>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">Signup Form</h3>
                </div>

                <div class="row">
                  <div class="col-md-10 mx-auto">
                    <form ref={formRef} autoComplete="off">
                      <div class="card-body">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Introducer ID / Mobile
                          </label>
                          <div class="col-sm-9">
                            <div class="input-group input-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Introducer ID / Mobile"
                                ref={introIdRef}
                              />
                              <span class="input-group-append">
                                <button
                                  type="button"
                                  class="btn btn-info btn-flat"
                                  onClick={verifyIntro}
                                >
                                  Verify
                                </button>
                              </span>
                            </div>
                            <p
                              style={{
                                fontWeight: "bold",
                                color: "green",
                                paddingTop: "5px",
                              }}
                            >
                              {introName}
                            </p>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            PAN Number
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="PAN Number"
                              maxLength={10}
                              ref={panNumberRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Title</label>
                          <div class="col-sm-9">
                            <select
                              name="title"
                              class="form-control"
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            >
                              <option value="">---Select Title---</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Name</label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Name"
                              ref={nameRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Date of Birth
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="date"
                              class="form-control"
                              ref={dobRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Gender</label>
                          <div class="col-sm-9">
                            <select
                              class="form-control"
                              onChange={(e) => {
                                setGender(e.target.value);
                              }}
                            >
                              <option value="">---Select Gender---</option>
                              <option defaultValue="Male">Male</option>
                              <option defaultValue="Female">Female</option>
                              <option defaultValue="Transgender">
                                Transgender
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Father's Name
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Father's Name"
                              ref={fathersNameRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Mobile Number
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="Mobile Number"
                              maxLength={10}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              ref={mobileRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Email ID
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="Email ID"
                              ref={emailRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            PIN Code
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="PIN Code"
                              maxLength={6}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                getLocationHandler(e.target.value);
                              }}
                              ref={pincodeRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">State</label>
                          <div class="col-sm-9">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            >
                              <option
                                value={location ? location[0]["Circle"] : ""}
                              >
                                {location
                                  ? location[0]["Circle"]
                                  : "---Select State---"}
                              </option>

                              {states &&
                                states.map((e, key) => {
                                  return (
                                    <option key={key} value={e.value}>
                                      {e.label}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">City</label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="City"
                              ref={cityRef}
                              defaultValue={
                                location ? location[0]["Region"] : ""
                              }
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            District
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="District"
                              ref={districtRef}
                              defaultValue={
                                location ? location[0]["District"] : ""
                              }
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Address</label>
                          <div class="col-sm-9">
                            <textarea
                              ref={addressRef}
                              className="form-control"
                              placeholder="Address"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Nominee Name
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Nominee Name"
                              ref={nomineeNameRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Nominee Relation
                          </label>
                          <div class="col-sm-9">
                            <select
                              name="title"
                              class="form-control"
                              onChange={(e) => {
                                setNomineeRelation(e.target.value);
                              }}
                            >
                              <option defaultValue="">
                                ---Select Nominee Relation---
                              </option>

                              <option defaultValue="Father">Father</option>
                              <option defaultValue="Mother">Mother</option>
                              <option defaultValue="Wife">Wife</option>
                              <option defaultValue="Husband">Husband</option>
                              <option defaultValue="Son">Son</option>
                              <option defaultValue="Daughter">Daughter</option>
                              <option defaultValue="Brother">Brother</option>
                              <option defaultValue="Sister">Sister</option>
                              <option defaultValue="Grandson">Grandson</option>
                              <option defaultValue="Granddaughter">
                                Granddaughter
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Beneficiary Name
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Beneficiary Name"
                              ref={beneficiaryNameRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Bank Name
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Bank Name"
                              ref={bankNameRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Account Number
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="Account Number"
                              ref={accountNumberRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            IFSC Code
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              name="last_name"
                              class="form-control"
                              placeholder="IFSC Code"
                              ref={ifscCodeRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Account Type
                          </label>
                          <div class="col-sm-9">
                            <select
                              name="title"
                              class="form-control"
                              onChange={(e) => {
                                setAccountType(e.target.value);
                              }}
                            >
                              <option defaultValue="">
                                ---Select Account Type---
                              </option>

                              <option defaultValue="Current">Current</option>
                              <option defaultValue="Savings">Savings</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            New Password
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="password"
                              class="form-control"
                              maxLength={15}
                              placeholder="New Password (Minimum 6 digit and Maximum 15 digit)"
                              ref={passwordRef}
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">New PIN</label>
                          <div class="col-sm-9">
                            <input
                              type="password"
                              class="form-control"
                              placeholder="New PIN (4 digit)"
                              maxLength={4}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              ref={pinRef}
                            />
                          </div>
                        </div>

                        <div class="card-footer text-center">
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={addUserHandler}
                          >
                            Submit
                          </button>
                          <button type="reset" class="btn btn-default ml-5">
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={open} onHide={handleClose}>
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">OTP Verification</h4>
              <button
                type="button"
                class="close"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="alert alert-success" role="alert">
                <b>Success! </b> {message}
              </div>

              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setMobileOtp(e.target.value);
                  }}
                  class="form-control"
                  placeholder="OTP"
                  maxLength={6}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-default"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={verifyOtpHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default UserSignup;
