import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import CreditService from "../../../services/Admin/CreditService";
import SettingsService from "../../../services/Admin/SettingsService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ManageCronJob = () => {
  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettingsService.cronJobStatus(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData["data"];

        if (data[0]["value"] == "Y") {
          setJobName("1");
        } else if (data[1]["value"] == "Y") {
          setJobName("2");
        } else if (data[2]["value"] == "Y") {
          setJobName("3");
        } else {
          setJobName("4");
        }
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const updateCronJob = async (jobName) => {
    if (!jobName) {
      Swal.fire("Opps!", "Invalid Data", "error");
      return;
    }

    let data = [
      {
        name: "cron_job_disburse_commission",
        value: jobName === "1" ? "Y" : "N",
      },
      {
        name: "cron_job_disburse_upline_commission",
        value: jobName === "2" ? "Y" : "N",
      },
      {
        name: "cron_job_send_wp_message",
        value: jobName === "3" ? "Y" : "N",
      },
    ];

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettingsService.updateCronJob(token, data);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setJobName(jobName);
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [jobName, setJobName] = React.useState("4");

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Manage Cron Job">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Manage Cron Job</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto pt-3">
                      <form autoComplete="off">
                        <div class="card-body">
                          <div class="form-group">
                            <div class="custom-control custom-switch custom-switch-on-success">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch1"
                                checked={jobName === "1"}
                                onChange={() => updateCronJob("1")}
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch1"
                              >
                                Cron Job - Disburse Commission
                              </label>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="custom-control custom-switch custom-switch-on-success">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch2"
                                checked={jobName === "2"}
                                onChange={() => updateCronJob("2")}
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch2"
                              >
                                Cron Job - Disburse Upline Commission
                              </label>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="custom-control custom-switch custom-switch-on-success">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch3"
                                checked={jobName === "3"}
                                onChange={() => updateCronJob("3")}
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch3"
                              >
                                Cron Job - Send Wp Message
                              </label>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="custom-control custom-switch custom-switch-on-success">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch4"
                                checked={jobName === "4"}
                                onChange={() => updateCronJob("4")}
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch4"
                              >
                                Disable
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ManageCronJob;
