import common from "../../utill/Common";

class AuthService {
  sendOtp = async (user_id, password, lat, long) => {
    const url = common.baseUrl + "user/login/send_otp";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id,
        password,
        lat,
        long,
        login_via: "WEB",
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  verifyOtp = async (token, otp) => {
    const url = common.baseUrl + "user/login/verify_otp";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        otp,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  forgotPassword = async (user_id, pan_number) => {
    const url = common.baseUrl + "user/forgot_password/send_otp";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id,
        pan_number,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  forgotPasswordOtpVerify = async (token, otp, password) => {
    const url = common.baseUrl + "user/forgot_password/verify_otp";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp: otp,
        token: token,
        new_password: password,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  isLoggedIn = () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  getAccessToken = () => {
    const logged_in_on = localStorage.getItem("timestamp");
    const token = localStorage.getItem("access_token");

    if (logged_in_on === null || token === null) {
      //  localStorage.clear();
    }

    return token;
  };

  getUserID = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    return user.user_id;
  };

  getName = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    return user.name;
  };

  getMobile = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    return user.mobile;
  };

  getUserType = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    return user.user_type;
  };

  logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("timestamp");
    return true;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
