import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToWords } from "to-words";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import AgentsService from "../../../services/Admin/AgentsService";
import PaymentService from "../../../services/Admin/PaymentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const CommissionToInvestment = () => {
  const loadingCtx = useContext(LoadingContext);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState();

  const [id, setId] = useState();

  const formRef = useRef();
  const userIdRef = useRef();
  const [amount, setAmount] = useState();
  const remarkRef = useRef();

  const submitHandler = async () => {
    const remark = remarkRef.current.value;

    if (!id) {
      Swal.fire("Opps!", "Invalid User", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.commissionToInvestment(
        token,
        id,
        amount,
        remark
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchHandler = async () => {
    const userId = userIdRef.current.value;

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setOpen(true);
        setData(responseData.data.result);
        setId(responseData.data.result.id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Commission To Investment">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Commission To Investment</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-10 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          {!open && (
                            <div class="form-group row">
                              <div class="input-group input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter User ID/Mobile Number"
                                  ref={userIdRef}
                                />
                                <span class="input-group-append">
                                  <button
                                    type="button"
                                    class="btn btn-info btn-flat"
                                    onClick={fetchHandler}
                                  >
                                    Go
                                  </button>
                                </span>
                              </div>
                            </div>
                          )}

                          {open && data && (
                            <div>
                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Name
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      data.name + " (" + data.user_id + ")"
                                    }
                                    readOnly
                                  />

                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      color: "green",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    Commission Balance: ₹
                                    {data.tbl_wallet_balance.commission_wallet}
                                  </p>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Amount
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Amount"
                                    maxLength={10}
                                    style={{ fontWeight: "bold" }}
                                    defaultValue={amount}
                                    onChange={(e) => {
                                      setAmount(e.target.value);
                                    }}
                                  />
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {toWords.convert(amount ? amount : "0")}
                                  </p>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Remark
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    ref={remarkRef}
                                    className="form-control"
                                    placeholder="Remark"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div class="card-footer text-center">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={submitHandler}
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-default ml-5"
                                  onClick={() => {
                                    setOpen(!open);
                                  }}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default CommissionToInvestment;
