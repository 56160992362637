import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { FileUploader } from "react-drag-drop-files";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const SendWpMessage = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const messageRef = useRef();

  const submitHandler = async () => {
    const message = messageRef.current.value;

    if (!message) {
      Swal.fire("Opps!", "Invalid Message", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = props.id ? props.id : props.match.params.id;

      let responseData;

      if (!file) {
        responseData = await AgentsService.sendWpMessage(
          token,
          userId,
          message
        );
      } else {
        responseData = await AgentsService.sendMessageWithMedia(
          token,
          userId,
          message,
          file
        );
      }

      const status = responseData.status;
      const message1 = responseData.message;

      if (status === "SUCCESS") {
        // formRef.current.reset();
        Swal.fire("Success!", message1, "success");
      } else {
        Swal.fire("Opps!", message1, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [file, setFile] = React.useState(null);

  const handleChange = (file) => {
    console.log(JSON.stringify(file));
    setFile(file);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Send Whatsapp Message">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Send Whatsapp Message</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef}>
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Message
                            </label>
                            <div class="col-sm-9">
                              <textarea
                                class="form-control"
                                placeholder="Enter your text message here... (Maximum 6000 characters)"
                                rows="6"
                                ref={messageRef}
                              />
                              <small>
                                Maximum 6000 characters. Multiple lines are
                                allowed. Message text can be empty if there is a
                                media file attached.{" "}
                                <a
                                  href="https://faq.whatsapp.com/539178204879377/?locale=en_US"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  WhatsApp rich text syntax
                                </a>{" "}
                                is allowed.
                              </small>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"></label>
                            <div class="col-sm-9">
                              <FileUploader
                                multiple={false}
                                required={false}
                                maxSize={1}
                                label={
                                  "Drag & Drop file or click here to upload a file from disk (Maximum 1 MB)"
                                }
                                handleChange={handleChange}
                                name="file"
                                // types={fileTypes}
                              />
                              <p>
                                <p>
                                  {file
                                    ? `File name: ${file.name}`
                                    : "no files uploaded yet"}
                                </p>
                              </p>
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default SendWpMessage;
