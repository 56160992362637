import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AdminCashDepositService from "../../../services/Admin/AdminCashDepositService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
// import AddCategoryDialog from "./AddCategoryDialog";

const AddCashDeposit = () => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const amountRef = useRef();
  const paymentDateRef = useRef();
  const remarkRef = useRef();

  const submitHandler = async () => {
    const amount = amountRef.current.value;
    const paymentDate = paymentDateRef.current.value;
    const remark = remarkRef.current.value;

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!paymentDate) {
      Swal.fire("Opps!", "Invalid Payment Date", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AdminCashDepositService.add(
        token,
        amount,
        paymentDate,
        remark
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const [amountInWord, setAmountInWord] = useState();

  const getWord = () => {
    const amount = amountRef.current.value;
    if (amount) {
      let words = toWords.convert(amount);
      setAmountInWord(words);
    } else {
      setAmountInWord();
    }
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Cash Deposit">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Add Cash Deposit</h3>
                    <div class="card-tools">
                      <a
                        href="/admin/cash_deposit_history"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Cash Deposit History
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Amount
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Amount"
                                maxLength={10}
                                ref={amountRef}
                                style={{ fontWeight: "bold" }}
                                onChange={getWord}
                              />
                              <p
                                style={{
                                  fontWeight: "bold",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                {amountInWord}
                              </p>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Payment Date
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="date"
                                class="form-control"
                                ref={paymentDateRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Remark
                            </label>
                            <div class="col-sm-10">
                              <textarea
                                class="form-control"
                                ref={remarkRef}
                              ></textarea>
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      {/* <AddCategoryDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        expenseCategoryList={expenseCategoryList}
      /> */}
    </React.Fragment>
  );
};

export default AddCashDeposit;
