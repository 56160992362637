import common from "../../utill/Common";

class DbModuleService {
  add = async (
    token,
    parent_user_id,
    pan_number,
    title,
    name,
    dob,
    gender,
    fathers_name,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address,
    nominee_name,
    nominee_relation,
    beneficiary_name,
    bank_name,
    account_number,
    ifsc_code,
    account_type
  ) => {
    const url = common.baseUrl + "user/db_module/add_user";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        parent_user_id,
        pan_number,
        title,
        name,
        dob,
        gender,
        fathers_name,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
        nominee_name,
        nominee_relation,
        beneficiary_name,
        bank_name,
        account_number,
        ifsc_code,
        account_type,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  list = async (token) => {
    const url = common.baseUrl + "user/db_module/user_list";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getNameFromUserId = async (token, user_id) => {
    const url = common.baseUrl + "user/db_module/get_name_from_user_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        user_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  adminLogin = async (token) => {
    const url = common.baseUrl + "user/db_module/admin_login";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DbModuleService();
