import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

import AuthService from "../../../services/User/AuthService";

const Menu = () => {
  const [name, setName] = useState();

  useEffect(() => {
    getDetails();
  });

  const getDetails = () => {
    const name = AuthService.getName();
    setName(name);
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a
          href="user/dashboard"
          className="brand-link"
          style={{ textAlign: "center", backgroundColor: "#cff0a8" }}
        >
          <img
            src="/assets/img/nexxture-logo.png"
            alt="AdminLTE Logo"
            className="brand-image"
            style={{ textAlign: "center" }}
          />
          <br />

          {/* <span className="brand-text font-weight-light">Nexxture</span> */}
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="/assets/img/user-icon.png"
                className="img-circle elevation-2"
                alt="User"
                style={{ backgroundColor: "white" }}
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                {name ? name : ""}
              </a>
            </div>
          </div>
          {/* Sidebar Menu */}

          <BrowserView>
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
                <li className="nav-item">
                  <Link to="/user/dashboard" className="nav-link active">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>Dashboard</p>
                  </Link>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-chart-pie" />
                    <p>
                      Manage Users
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/user/add_user" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Add User</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/user/user_list" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>User List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/user/user_list_tree_view" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>User List (Tree View)</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-wallet" />
                    <p>
                      Investment Module
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/user/new_investment_request"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>New Investment Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/user/investment_request_history"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>Investment Req History</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/user/investment_history" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Investment History</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/user/team_investment_history"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>Team Investment History</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-wallet" />
                    <p>
                      Withdrawal Module
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/user/new_withdrawal_request"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>New Withdrawal Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/user/withdrawal_history" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Withdrawal History</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/user/downline_withdrawal_requests"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>Downline With. Requests</p>
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-wallet" />
                    <p>
                      Income Report
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/user/income_summary" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Income Summary</p>
                      </Link>

                      <Link to="/user/commission_history" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Commission History</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                    <Link to="/user/change_password" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Income History</p>
                    </Link>
                  </li> */}
                  </ul>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-user" />
                    <p>
                      My Profile
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/user/my_profile" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>My Profile</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/user/change_password" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Change Password</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/user/reset_pin" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Reset PIN</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="/user/logout" className="nav-link">
                    <i className="nav-icon fas fa-sign-out-alt" />
                    <p>Logout</p>
                  </a>
                </li>
              </ul>
            </nav>
          </BrowserView>

          <MobileView>
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
                <li className="nav-item">
                  <a href="/user/dashboard" className="nav-link active">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>Dashboard</p>
                  </a>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-chart-pie" />
                    <p>
                      Manage Users
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/user/add_user" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Add User</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/user/user_list" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>User List</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/user/user_list_tree_view" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>User List (Tree View)</p>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-wallet" />
                    <p>
                      Investment Module
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a
                        href="/user/new_investment_request"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>New Investment Request</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/user/investment_request_history"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>Investment Req History</p>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="/user/investment_history" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Investment History</p>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        href="/user/team_investment_history"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>Team Investment History</p>
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-wallet" />
                    <p>
                      Withdrawal Module
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a
                        href="/user/new_withdrawal_request"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>New Withdrawal Request</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/user/withdrawal_history" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Withdrawal History</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/user/downline_withdrawal_requests"
                        className="nav-link"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p>Downline With. Requests</p>
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-wallet" />
                    <p>
                      Income Report
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/user/income_summary" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Income Summary</p>
                      </a>

                      <a href="/user/commission_history" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Commission History</p>
                      </a>
                    </li>
                    {/* <li className="nav-item">
                    <Link to="/user/change_password" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Income History</p>
                    </Link>
                  </li> */}
                  </ul>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-user" />
                    <p>
                      My Profile
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/user/my_profile" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>My Profile</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/user/change_password" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Change Password</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/user/reset_pin" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Reset PIN</p>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="/user/logout" className="nav-link">
                    <i className="nav-icon fas fa-sign-out-alt" />
                    <p>Logout</p>
                  </a>
                </li>
              </ul>
            </nav>
          </MobileView>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
};

export default Menu;
