import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToWords } from "to-words";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import AgentsService from "../../../services/Admin/AgentsService";
import PaymentService from "../../../services/Admin/PaymentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const AddNewInvestment = () => {
  const loadingCtx = useContext(LoadingContext);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [chequeStatus, setChequeStatus] = useState();

  const [id, setId] = useState();

  const formRef = useRef();
  const userIdRef = useRef();
  const remarkRef = useRef();
  const chequeDetailsRef = useRef();

  const [selectedPayment, setSelectedPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState();

  const submitHandler = async () => {
    const remark = remarkRef.current.value;
    const chequeDetails = chequeDetailsRef.current.value;

    if (!id) {
      Swal.fire("Opps!", "Invalid User", "error");
      return;
    }

    if (!totalAmount || totalAmount < 1) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    const orderIds = [];

    selectedPayment.forEach((e, index) => {
      orderIds.push(e.order_id);
    });

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.addNewInvestment(
        token,
        id,
        orderIds,
        remark,
        chequeStatus,
        chequeDetails
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchHandler = async () => {
    const userId = userIdRef.current.value;

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setOpen(true);
        setData(responseData.data.result);
        setId(responseData.data.result.id);
        fetchUnlinkedPayments();
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const [unlinkedPayments, setUnlinkedPayments] = useState([]);

  const fetchUnlinkedPayments = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.unlinkedPayments(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setUnlinkedPayments(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const getPaymentReceiptLink = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.viewPaymentReceipt(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        window.open(responseData.data.url, "_blank");

        //Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const handleTotalAmount = (rows) => {
    let amount = 0;

    rows.forEach((val, index) => {
      amount += val.amount;
    });

    setTotalAmount(amount);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add New Investment">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add New Investment</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-10 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          {!open && (
                            <div class="form-group row">
                              <div class="input-group input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter User ID/Mobile Number"
                                  ref={userIdRef}
                                />
                                <span class="input-group-append">
                                  <button
                                    type="button"
                                    class="btn btn-info btn-flat"
                                    onClick={fetchHandler}
                                  >
                                    Go
                                  </button>
                                </span>
                              </div>
                            </div>
                          )}

                          {open && data && (
                            <div>
                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Name
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      data.name + " (" + data.user_id + ")"
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>

                              <div class="form-group row pb-3">
                                <label class="col-sm-3 col-form-label">
                                  Select Payments
                                </label>
                                <div class="col-sm-9">
                                  <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={unlinkedPayments}
                                    getOptionLabel={(option) =>
                                      option.payment_ref_id
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Payments"
                                      />
                                    )}
                                    onChange={(e, v) => {
                                      setSelectedPayments(v);
                                      handleTotalAmount(v);
                                      // console.log(JSON.stringify(v));
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row pb-3 justify-content-center">
                                {selectedPayment.map((e) => (
                                  <div className="col-md-6">
                                    <div class="card">
                                      <div class="card-header">
                                        <h3 class="card-title">
                                          <span
                                            class="badge badge-primary"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {e.payment_mode}
                                          </span>
                                        </h3>
                                        <div class="card-tools">
                                          <b>{e.order_id}</b>
                                        </div>
                                      </div>

                                      <div class="card-body">
                                        <ul class="list-group list-group-unbordered mb-3">
                                          <li class="list-group-item">
                                            <b>Amount</b>{" "}
                                            <a class="float-right">
                                              <b
                                                style={{
                                                  fontSize: "17px",
                                                  color: "red",
                                                }}
                                              >
                                                <i class="fas fa-rupee-sign"></i>
                                                {e.amount}
                                              </b>
                                            </a>
                                          </li>
                                          <li class="list-group-item">
                                            <b>Amount in word</b>{" "}
                                            <a class="float-right">
                                              <b
                                                style={{
                                                  fontSize: "17px",
                                                  color: "red",
                                                }}
                                              >
                                                {toWords.convert(e.amount)}
                                              </b>
                                            </a>
                                          </li>
                                          <li class="list-group-item">
                                            <b>Payment Date</b>{" "}
                                            <a class="float-right">
                                              <b style={{ fontSize: "17px" }}>
                                                {e.payment_date}
                                              </b>
                                            </a>
                                          </li>
                                          <li class="list-group-item">
                                            <b>Payment REF ID</b>{" "}
                                            <a class="float-right">
                                              <b style={{ fontSize: "17px" }}>
                                                {e.payment_ref_id}
                                              </b>
                                            </a>
                                          </li>
                                          <li class="list-group-item">
                                            <b>Payment Receipt</b>{" "}
                                            <a class="float-right">
                                              <span
                                                className="badge badge-primary"
                                                onClick={() => {
                                                  getPaymentReceiptLink(e.id);
                                                }}
                                              >
                                                Download
                                              </span>
                                            </a>
                                          </li>
                                          <li class="list-group-item">
                                            <b>Remark</b>{" "}
                                            <a class="float-right">
                                              <b style={{ fontSize: "17px" }}>
                                                {e.remark}
                                              </b>
                                            </a>
                                          </li>
                                          <li class="list-group-item">
                                            <b>Timestamp</b>{" "}
                                            <a class="float-right">
                                              <b style={{ fontSize: "17px" }}>
                                                {e.created_on}
                                              </b>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Amount
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Amount"
                                    maxLength={10}
                                    style={{ fontWeight: "bold" }}
                                    defaultValue={totalAmount}
                                    disabled={true}
                                  />
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {toWords.convert(
                                      totalAmount ? totalAmount : "0"
                                    )}
                                  </p>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Remark
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    ref={remarkRef}
                                    className="form-control"
                                    placeholder="Remark"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Cheque Status
                                </label>
                                <div class="col-sm-9">
                                  <select
                                    name="title"
                                    class="form-control"
                                    onChange={(e) => {
                                      setChequeStatus(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select Cheque Status---
                                    </option>
                                    <option value="Not Applicable">
                                      Not Applicable
                                    </option>
                                    <option value="Delivered">Delivered</option>
                                  </select>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Cheque Details
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    ref={chequeDetailsRef}
                                    className="form-control"
                                    placeholder="Cheque Details"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div class="card-footer text-center">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={submitHandler}
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-default ml-5"
                                  onClick={() => {
                                    setOpen(!open);
                                  }}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddNewInvestment;
