import common from "../../utill/Common";

class ProfileService {
  getBalance = async (token) => {
    const url = common.baseUrl + "admin/profile/get_balance";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProfileService();
