import common from "../../utill/Common";

class SettingsService {
  updateCronJob = async (token, data) => {
    const url = common.baseUrl + "admin/settings/update_cron_job";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ data: data }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  cronJobStatus = async (token) => {
    const url = common.baseUrl + "admin/settings/cron_job_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SettingsService();
