import React, { useState, useEffect } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/MenuOpen";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import IncomeReportService from "../../../services/User/IncomeReportService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const IncomeSummary = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  const [totalAmount, setTotalAmount] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month]);

  const fetch = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    IncomeReportService.incomeSummary(token, year, month)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        const totalAmount = responseData.data.total_amount;
        setTotalAmount(totalAmount);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {row.tbl_user ? row.tbl_user.user_id : row.param5_value} (
            {row.tbl_user ? row.tbl_user.name : ""})
          </span>
        </div>
      ),
    },
    {
      name: "Investment Details",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>Avl Investment Amount: ₹{row.param2_value}</span> <br />
          <span>Number of Days: {row.param3_value}</span>
          <br />
          <span>Percentage: {row.param1_value}%</span>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Income Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b>Amt: ₹{row.order_amount}</b>
          <br />
          <small style={{ color: "red" }}>
            TDS Amount: - ₹{row.tds_amount}
          </small>
          <br />
          <b>
            Amt After TDS Deducted:{" "}
            <b style={{ color: "green" }}>₹{row.amount}</b>
          </b>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Income Summary">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Income Summary</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setYear(e.target.value);
                            }}
                          >
                            <option value="">Select Year</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="form-group">
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setMonth(e.target.value);
                            }}
                          >
                            <option value="">Select Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                    />

                    <br />
                    {data && (
                      <div>
                        <p>
                          Total Amount:{" "}
                          <b style={{ color: "green" }}>₹{totalAmount}</b>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default IncomeSummary;
