import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AdminCashDepositService from "../../../services/Admin/AdminCashDepositService";
import PaymentService from "../../../services/Admin/PaymentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
// import AddCategoryDialog from "./AddCategoryDialog";

const EditPayment = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.fetchById(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData.data;
        setData(data);
        //   setPaymentMode(data.payment_mode);
        setBankId(data.bank_id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [banks, setBanks] = useState([]);

  React.useEffect(() => {
    fetchData();
    fetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanks = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.fetchBanks(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setBanks(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  // const [isAmountDisabled, setIsAmountDisabled] = useState(false);
  const [bankId, setBankId] = useState();
  // const [paymentMode, setPaymentMode] = useState();

  const formRef = useRef();
  //  const amountRef = useRef();
  const paymentDateRef = useRef();

  const paymentRefIdRef = useRef();
  const remarkRef = useRef();

  const submitHandler = async () => {
    // const amount = amountRef.current.value;
    // const paymentDate = paymentDateRef.current.value;

    const paymentRefId = paymentRefIdRef.current.value;
    const remark = remarkRef.current.value;

    if (!bankId) {
      Swal.fire("Opps!", "Invalid Bank", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await PaymentService.update(
        token,
        id,
        bankId,
        // amount,
        // paymentMode,
        // paymentDate,
        paymentRefId,
        remark
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  //   const [amountInWord, setAmountInWord] = useState();

  //   const getWord = () => {
  //     const amount = amountRef.current.value;
  //     if (amount) {
  //       let words = toWords.convert(amount);
  //       setAmountInWord(words);
  //     } else {
  //       setAmountInWord();
  //     }
  //   };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit Payment">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Edit Payment</h3>
                    <div class="card-tools">
                      <a
                        href="/admin/payment_history"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Payment History
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-8 mx-auto">
                      {data && banks && (
                        <form ref={formRef} autoComplete="off">
                          <div class="card-body">
                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Select Bank
                              </label>
                              <div class="col-sm-10">
                                <Autocomplete
                                  options={banks}
                                  getOptionLabel={(option) => option.bank_name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Bank"
                                    />
                                  )}
                                  onChange={(e, v) => {
                                    setBankId(v ? v.id : "");
                                  }}
                                  defaultValue={banks.find(
                                    (v) => v.id.toString() === data.bank_id
                                  )}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Payment Ref ID
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Payment Ref ID"
                                  ref={paymentRefIdRef}
                                  defaultValue={data ? data.payment_ref_id : ""}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Remark
                              </label>
                              <div class="col-sm-10">
                                <textarea
                                  class="form-control"
                                  ref={remarkRef}
                                  defaultValue={data ? data.remark : ""}
                                ></textarea>
                              </div>
                            </div>

                            <div class="card-footer text-center">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={submitHandler}
                              >
                                Submit
                              </button>
                              <button type="reset" class="btn btn-default ml-5">
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditPayment;
