import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AdminCashDepositService from "../../../services/Admin/AdminCashDepositService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

import "./ReceiptStyle.css";

const CashDepositReceipt = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();
  const [userData, setUserData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AdminCashDepositService.fetchById(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        //alert(JSON.stringify(responseData.data));
        setData(responseData.data);
        document.title = responseData.data.order_id;
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  return (
    <React.Fragment>
      <div className="">
        {/* <Templete t1="Home" t2="View Agent">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">View Agent</h3>
                  </div> */}

        {/* <div class="row">
        <div class="col-md-6 mx-auto"> */}

        {data && (
          <div>
            <div className="pb-3" style={{ textAlign: "center" }}>
              <img src="/assets/img/nexxture-logo.png" width="200" />
            </div>

            <table
              cellpadding="4"
              cellspacing="0"
              border="1"
              width="100%"
              align="center"
            >
              <tr>
                <td colspan="2">
                  <table border="0">
                    <tr>
                      <td width="100%" align="center">
                        <font color="#000000" size="+2">
                          <b>CASH DEPOSIT RECEIPT</b>
                        </font>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Amount</b>
                </td>
                <td width="50%">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      color: "green",
                    }}
                  >
                    ₹{data.amount}
                  </span>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Amount in words</b>
                </td>
                <td width="50%">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "red",
                    }}
                  >
                    {toWords.convert(data.amount)}
                  </span>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Payment Date</b>
                </td>
                <td width="50%">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      //    color: "blue",
                    }}
                  >
                    {data.payment_date}
                  </span>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Remark</b>
                </td>
                <td width="50%">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      //  color: "blue",
                    }}
                  >
                    {data.remark}
                  </span>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Order ID</b>
                </td>
                <td width="50%">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      //  color: "blue",
                    }}
                  >
                    {data.order_id}
                  </span>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Timestamp</b>
                </td>
                <td width="50%">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      // color: "blue",
                    }}
                  >
                    {data.created_on}
                  </span>
                </td>
              </tr>
            </table>

            <div class="pt-5 text-center no-print">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  window.print();
                }}
              >
                Print
              </button>
              <button
                type="button"
                class="btn btn-default ml-5"
                onClick={() => {
                  // history.push("/agent_list");
                  window.close();
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {/* </div>
      </div> */}
        {/* </div>
              </div>
            </div>
          </div>
        </section>
      </Templete> */}
      </div>
    </React.Fragment>
  );
};

export default CashDepositReceipt;
