import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ResetPin = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();

  const formRef = useRef();
  const loginPasswordRef = useRef();
  const newPinRef = useRef();
  const confirmNewPinRef = useRef();

  const resetPinHandler = async () => {
    const loginPassword = loginPasswordRef.current.value;
    const newPin = newPinRef.current.value;
    const confirmNewPin = confirmNewPinRef.current.value;

    if (!loginPassword) {
      Swal.fire("Opps!", "Invalid Login Password", "error");
      return;
    }

    if (!newPin) {
      Swal.fire("Opps!", "Invalid New PIN", "error");
      return;
    }

    if (!confirmNewPin) {
      Swal.fire("Opps!", "Invalid Confirm New PIN", "error");
      return;
    }

    if (newPin !== confirmNewPin) {
      Swal.fire("Opps!", "New PIN & Confirm New PIN does not match", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.resetPin(
        token,
        loginPassword,
        newPin
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Reset PIN">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Reset PIN</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Login Password
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="New Password"
                                ref={loginPasswordRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              New PIN
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="New PIN"
                                ref={newPinRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Confirm New PIN
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Confirm New PIN"
                                ref={confirmNewPinRef}
                              />
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={resetPinHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ResetPin;
