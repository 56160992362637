import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import PackageService from "../../../services/Admin/PackageService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";

const EditUserForm = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [sessionUserType, setSessionUserType] = useState();

  useEffect(() => {
    fetchData();
    fetchUserList();
    fetchPackageList();
    getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    setSessionUserType(_userType);
  };

  const [leaderData, setLeaderData] = useState();
  const [leaderId, setLeaderId] = useState();
  const [clientType, setClientType] = useState();
  const [userType, setUserType] = useState();
  const [title, setTitle] = useState();
  const [gender, setGender] = useState();
  const [state, setState] = useState();
  const [nomineeRelation, setNomineeRelation] = useState();
  const [status, setStatus] = useState();
  const [rank, setRank] = useState();
  const [is2fa, setIs2fa] = useState();
  const [isHide, setIsHide] = useState();
  const [enableWa, setEnableWa] = useState();

  const [UserList, setUserList] = useState();
  const [data, setData] = useState();
  const [parentData, setParentData] = useState();
  const [parentUserId, setParentUserId] = useState();

  const [PackageList, setPackageList] = useState();
  const [packageId, setPackageId] = useState();

  const formRef = useRef();
  const panNumberRef = useRef();
  const nameRef = useRef();
  const dobRef = useRef();
  const fathersNameRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();
  const pincodeRef = useRef();
  const cityRef = useRef();
  const districtRef = useRef();
  const addressRef = useRef();
  const nomineeNameRef = useRef();
  const joiningDateRef = useRef();
  const paymentDateRef = useRef();
  const mbtAmountRef = useRef();
  const bdcAmountRef = useRef();

  const updateHandler = async () => {
    const panNumber = panNumberRef.current.value;
    const name = nameRef.current.value;
    const dob = dobRef.current.value;
    const fathersName = fathersNameRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;
    const pincode = pincodeRef.current.value;
    const city = cityRef.current.value;
    const district = districtRef.current.value;
    const address = addressRef.current.value;
    const nomineeName = nomineeNameRef.current.value;
    const joiningDate = joiningDateRef.current.value;
    const paymentDate = paymentDateRef.current.value;
    const mbtAmount = mbtAmountRef.current.value;
    const bdcAmount = bdcAmountRef.current.value;

    if (userType === "Admin" && sessionUserType === "Employee") {
      Swal.fire("Opps!", "Access Denided", "error");
      return;
    }

    if (!userType) {
      Swal.fire("Opps!", "Invalid User Type", "error");
      return;
    }

    if (!parentUserId) {
      Swal.fire("Opps!", "Invalid Parent", "error");
      return;
    }

    if (!packageId) {
      Swal.fire("Opps!", "Invalid Package", "error");
      return;
    }

    if (!panNumber) {
      Swal.fire("Opps!", "Invalid PAN Number", "error");
      return;
    }

    if (!title) {
      Swal.fire("Opps!", "Invalid Title", "error");
      return;
    }

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    // if (!dob) {
    //   Swal.fire("Opps!", "Invalid Date of Birth", "error");
    //   return;
    // }

    // if (!gender) {
    //   Swal.fire("Opps!", "Invalid Gender", "error");
    //   return;
    // }

    // if (!fathersName) {
    //   Swal.fire("Opps!", "Invalid Fathers Name", "error");
    //   return;
    // }

    if (!mobile || mobile.length < 10 || mobile.length > 10) {
      Swal.fire("Opps!", "Invalid Mobile Number", "error");
      return;
    }

    if (!email) {
      Swal.fire("Opps!", "Invalid Email ID", "error");
      return;
    }

    if (!pincode) {
      Swal.fire("Opps!", "Invalid PIN Code", "error");
      return;
    }

    if (!state) {
      Swal.fire("Opps!", "Invalid State", "error");
      return;
    }

    if (!city) {
      Swal.fire("Opps!", "Invalid City", "error");
      return;
    }

    if (!district) {
      Swal.fire("Opps!", "Invalid District", "error");
      return;
    }

    if (!address) {
      Swal.fire("Opps!", "Invalid Address", "error");
      return;
    }

    if (!nomineeName) {
      Swal.fire("Opps!", "Invalid Nominee Name", "error");
      return;
    }

    if (!nomineeRelation) {
      Swal.fire("Opps!", "Invalid Nominee Relation", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    if (!joiningDate) {
      Swal.fire("Opps!", "Invalid Joining Date", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = props.userId ? props.userId : props.match.params.userId;

      const responseData = await AgentsService.update(
        token,
        userId,
        clientType,
        userType,
        leaderId,
        parentUserId,
        packageId,
        panNumber,
        rank,
        title,
        name,
        dob,
        gender,
        fathersName,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
        nomineeName,
        nomineeRelation,
        status,
        joiningDate,
        paymentDate,
        is2fa,
        isHide,
        mbtAmount,
        bdcAmount,
        enableWa
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        // formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchData = async () => {
    loadingCtx.startLoading();

    const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data.result);
        setParentData(responseData.data.parent_result);
        setLeaderData(responseData.data.leader_result);
        setLeaderId(responseData.data.leader_result.leader_user_id);
        setClientType(responseData.data.result.client_type);
        setUserType(responseData.data.result.user_type);
        setParentUserId(responseData.data.parent_result.parent_user_id);
        setPackageId(responseData.data.result.package_id);
        setTitle(responseData.data.result.title);
        setGender(responseData.data.result.gender);
        setState(responseData.data.result.state);
        setNomineeRelation(responseData.data.result.nominee_relation);
        setStatus(responseData.data.result.status);
        setIs2fa(responseData.data.result.is_2fa);
        setIsHide(responseData.data.result.is_hide);
        setRank(responseData.data.result.rank);
        setEnableWa(responseData.data.result.enable_wa);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchUserList = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.list(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setUserList(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchPackageList = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PackageService.fetchAll(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setPackageList(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-6 mx-auto">
          {data && (
            <form ref={formRef} autoComplete="off">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Client Type</label>
                  <div class="col-sm-9">
                    {sessionUserType === "Admin" && (
                      <select
                        name="title"
                        class="form-control"
                        onChange={(e) => {
                          setClientType(e.target.value);
                        }}
                      >
                        <option defaultValue={data.client_type}>
                          {data.client_type}
                        </option>
                        <option value="Investor">Investor</option>
                        <option value="Remisier">Remisier</option>
                      </select>
                    )}
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">User Type</label>
                  <div class="col-sm-9">
                    {sessionUserType === "Admin" && (
                      <select
                        name="title"
                        class="form-control"
                        onChange={(e) => {
                          setUserType(e.target.value);
                        }}
                      >
                        <option defaultValue={data.user_type}>
                          {data.user_type}
                        </option>
                        <option defaultValue="User">User</option>
                        {sessionUserType === "Admin" && (
                          <option value="Employee">Employee</option>
                        )}
                      </select>
                    )}

                    {sessionUserType === "Employee" && (
                      <select
                        name="title"
                        class="form-control"
                        onChange={(e) => {
                          setUserType(e.target.value);
                        }}
                        readOnly
                      >
                        <option defaultValue={data.user_type}>
                          {data.user_type}
                        </option>
                        {/* <option defaultValue="User">User</option>
                        {sessionUserType === "Admin" && (
                          <option value="Employee">Employee</option>
                        )} */}
                      </select>
                    )}
                  </div>
                </div>

                {sessionUserType === "Admin" && leaderData && UserList && (
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Team Leader</label>
                    <div class="col-sm-9">
                      <Autocomplete
                        options={UserList}
                        getOptionLabel={(option) =>
                          option.name + " - " + option.user_id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Select Team Leader" />
                        )}
                        onChange={(e, v) => {
                          setLeaderId(v ? v.user_id : null);
                        }}
                        defaultValue={UserList.find(
                          (v) => v.user_id === leaderData.leader_user_id
                        )}
                      />
                    </div>
                  </div>
                )}

                {sessionUserType === "Admin" && parentData && UserList && (
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Parent User</label>
                    <div class="col-sm-9">
                      <Autocomplete
                        options={UserList}
                        getOptionLabel={(option) =>
                          option.name + " - " + option.user_id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Select Parent User" />
                        )}
                        onChange={(e, v) => {
                          setParentUserId(v ? v.user_id : null);
                        }}
                        defaultValue={UserList.find(
                          (v) => v.user_id === parentData.parent_user_id
                        )}
                      />
                    </div>
                  </div>
                )}

                {sessionUserType === "Admin" && PackageList && (
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                      Select Package
                    </label>
                    <div class="col-sm-9">
                      <Autocomplete
                        options={PackageList}
                        getOptionLabel={(option) =>
                          option.name + " (" + option.amount + "%)"
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Select Package" />
                        )}
                        onChange={(e, v) => {
                          setPackageId(v ? v.id : null);
                        }}
                        defaultValue={PackageList.find(
                          (v) => v.id.toString() === data.package_id
                        )}
                      />
                    </div>
                  </div>
                )}

                {sessionUserType === "Admin" && (
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Old User ID</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        class="form-control"
                        readOnly
                        defaultValue={data.old_user_id}
                      />
                    </div>
                  </div>
                )}

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">User ID</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      readOnly
                      defaultValue={
                        props.userId ? props.userId : props.match.params.userId
                      }
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Rank</label>
                  <div class="col-sm-9">
                    <select
                      name="title"
                      class="form-control"
                      onChange={(e) => {
                        setRank(e.target.value);
                      }}
                    >
                      <option defaultValue={data.rank}>{data.rank}</option>
                      <option defaultValue="1 START">1 STAR</option>
                      <option defaultValue="2 START">2 STAR</option>
                      <option defaultValue="3 START">3 STAR</option>
                      <option defaultValue="4 START">4 STAR</option>
                      <option defaultValue="5 START">5 STAR</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">PAN Number</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="PAN Number"
                      maxLength={10}
                      ref={panNumberRef}
                      defaultValue={data.pan_number}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Title</label>
                  <div class="col-sm-9">
                    <select
                      name="title"
                      class="form-control"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    >
                      <option defaultValue={data.title}>{data.title}</option>
                      <option defaultValue="Mr.">Mr.</option>
                      <option defaultValue="Mrs.">Mrs.</option>
                      <option defaultValue="Ms.">Ms.</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Name</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Name"
                      ref={nameRef}
                      defaultValue={data.name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Date of Birth</label>
                  <div class="col-sm-9">
                    <input
                      type="date"
                      class="form-control"
                      ref={dobRef}
                      defaultValue={data.dob}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Gender</label>
                  <div class="col-sm-9">
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <option defaultValue={data.gender}>
                        {data.gender ? data.gender : "---Select Gender---"}
                      </option>
                      <option defaultValue="Male">Male</option>
                      <option defaultValue="Female">Female</option>
                      <option defaultValue="Transgender">Transgender</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Father's Name</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Father's Name"
                      ref={fathersNameRef}
                      defaultValue={data.fathers_name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Mobile Number</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Mobile Number"
                      maxLength={10}
                      onInput={(e) => {
                        e.target.defaultValue = e.target.defaultValue.replace(
                          /[^0-9]/g,
                          ""
                        );
                      }}
                      ref={mobileRef}
                      defaultValue={data.mobile}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Email ID</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="Email ID"
                      ref={emailRef}
                      defaultValue={data.email}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">PIN Code</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="PIN Code"
                      maxLength={6}
                      onInput={(e) => {
                        e.target.defaultValue = e.target.defaultValue.replace(
                          /[^0-9]/g,
                          ""
                        );
                      }}
                      ref={pincodeRef}
                      defaultValue={data.pincode}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">State</label>
                  <div class="col-sm-9">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                    >
                      <option defaultValue={data.state}>{data.state}</option>

                      {states &&
                        states.map((e, key) => {
                          return (
                            <option key={key} defaultValue={e.defaultValue}>
                              {e.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">City</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City"
                      ref={cityRef}
                      defaultValue={data.city}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">District</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="District"
                      ref={districtRef}
                      defaultValue={data.district}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Address</label>
                  <div class="col-sm-9">
                    <textarea
                      ref={addressRef}
                      className="form-control"
                      placeholder="Address"
                      rows="3"
                      defaultValue={data.address}
                    ></textarea>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Nominee Name</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nominee Name"
                      ref={nomineeNameRef}
                      defaultValue={data.nominee_name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">
                    Nominee Relation
                  </label>
                  <div class="col-sm-9">
                    <select
                      name="title"
                      class="form-control"
                      onChange={(e) => {
                        setNomineeRelation(e.target.value);
                      }}
                    >
                      {data.nominee_relation && (
                        <option defaultValue={data.nominee_relation}>
                          {data.nominee_relation}
                        </option>
                      )}

                      {!data.nominee_relation && (
                        <option defaultValue="">
                          ---Select Nominee Relation---
                        </option>
                      )}
                      <option defaultValue="Father">Father</option>
                      <option defaultValue="Mother">Mother</option>
                      <option defaultValue="Wife">Wife</option>
                      <option defaultValue="Husband">Husband</option>
                      <option defaultValue="Son">Son</option>
                      <option defaultValue="Daughter">Daughter</option>
                      <option defaultValue="Brother">Brother</option>
                      <option defaultValue="Sister">Sister</option>
                      <option defaultValue="Grandson">Grandson</option>
                      <option defaultValue="Granddaughter">
                        Granddaughter
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select
                      name="status"
                      class="form-control"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option defaultValue={data.status}>{data.status}</option>
                      <option defaultValue="Active">Active</option>
                      <option defaultValue="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>

                {sessionUserType === "Admin" && (
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                      Two-factor authentication
                    </label>
                    <div class="col-sm-9">
                      <select
                        name="status"
                        class="form-control"
                        onChange={(e) => {
                          setIs2fa(e.target.value);
                        }}
                      >
                        <option defaultValue={data.is_2fa}>
                          {data.is_2fa}
                        </option>
                        <option defaultValue="NO">NO</option>
                        <option defaultValue="YES">YES</option>
                      </select>
                    </div>
                  </div>
                )}

                {sessionUserType === "Admin" && (
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Is Hide</label>
                    <div class="col-sm-9">
                      <select
                        name="status"
                        class="form-control"
                        onChange={(e) => {
                          setIsHide(e.target.value);
                        }}
                      >
                        <option defaultValue={data.is_hide}>
                          {data.is_hide}
                        </option>
                        <option value=""></option>
                        <option defaultValue="YES">YES</option>
                      </select>
                    </div>
                  </div>
                )}

                {sessionUserType === "Admin" && (
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Enable Wa</label>
                    <div class="col-sm-9">
                      <select
                        name="status"
                        class="form-control"
                        onChange={(e) => {
                          setEnableWa(e.target.value);
                        }}
                      >
                        <option defaultValue={data.enable_wa}>
                          {data.enable_wa}
                        </option>
                        <option value="NO">NO</option>
                        <option value="YES">YES</option>
                        <option value=""></option>
                      </select>
                    </div>
                  </div>
                )}

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">MBT Amount</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      ref={mbtAmountRef}
                      defaultValue={data.mbt_amount}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">BDC Amount</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      ref={bdcAmountRef}
                      defaultValue={data.bdc_amount}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Joining Date</label>
                  <div class="col-sm-9">
                    <input
                      type="date"
                      class="form-control"
                      ref={joiningDateRef}
                      defaultValue={data.joining_date}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Date</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      ref={paymentDateRef}
                      defaultValue={data.payment_date}
                      placeholder="Only DD"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Created On</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      readOnly
                      defaultValue={data.created_on}
                    />
                  </div>
                </div>

                <div class="card-footer text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={updateHandler}
                  >
                    Submit
                  </button>
                  <button type="reset" class="btn btn-default ml-5">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditUserForm;
