import common from "../../utill/Common";

class ProfileService {
  resetPin = async (token, login_password, new_pin) => {
    const url = common.baseUrl + "user/profile/reset_pin";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        login_password,
        new_pin,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  changePassword = async (token, current_password, new_password) => {
    const url = common.baseUrl + "user/profile/change_password";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        current_password,
        new_password,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetch = async (token) => {
    const url = common.baseUrl + "user/profile/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getBalance = async (token) => {
    const url = common.baseUrl + "user/profile/get_balance";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (
    token,
    pan_number,
    title,
    name,
    dob,
    gender,
    fathers_name,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address
  ) => {
    const url = common.baseUrl + "user/profile/update";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        pan_number,
        title,
        name,
        dob,
        gender,
        fathers_name,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateBankDetails = async (
    token,

    beneficiary_name,
    bank_name,
    account_number,
    ifsc_code,
    account_type
  ) => {
    const url = common.baseUrl + "user/profile/update_bank_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        beneficiary_name,
        bank_name,
        account_number,
        ifsc_code,
        account_type,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateNomineeDetails = async (token, nominee_name, nominee_relation) => {
    const url = common.baseUrl + "user/profile/update_nominee_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        nominee_name,
        nominee_relation,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProfileService();
