import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import PackageService from "../../../services/Admin/PackageService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const EditPackage = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();
  const [status, setStatus] = useState();

  const formRef = useRef();
  const nameRef = useRef();
  const amountRef = useRef();

  const editPackageHandler = async () => {
    const name = nameRef.current.value;
    const amount = amountRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await PackageService.update(
        token,
        id,
        name,
        amount,
        status
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PackageService.fetchById(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
        setStatus(responseData.data.status);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit Package">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Edit Package</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      {data && (
                        <form ref={formRef} autoComplete="off">
                          <div class="card-body">
                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Name
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Name"
                                  ref={nameRef}
                                  defaultValue={data.name}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Amount
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Amount"
                                  ref={amountRef}
                                  defaultValue={data.amount}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Status
                              </label>
                              <div class="col-sm-10">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value={data.status}>
                                    {data.status}
                                  </option>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>

                            <div class="card-footer text-center">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={editPackageHandler}
                              >
                                Submit
                              </button>
                              <button type="reset" class="btn btn-default ml-5">
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditPackage;
