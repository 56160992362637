import React, { useEffect, useContext, useState, useRef } from "react";
import Swal from "sweetalert2";

import AuthService from "../../../services/User/AuthService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import OtpDialog from "./OtpDialog";

const ForgotPassword = () => {
  const loadingCtx = useContext(LoadingContext);

  const [errMsg, setErrMsg] = useState(true);
  const [token, setToken] = useState(true);

  const userIdRef = useRef();
  const panNumberRef = useRef();

  const submitHandler = async (e) => {
    e.preventDefault();

    const userId = userIdRef.current.value;
    const panNumber = panNumberRef.current.value;

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    if (!panNumber) {
      Swal.fire("Opps!", "Invalid PAN Number", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const data = await AuthService.forgotPassword(userId, panNumber);

      if (!data) {
        throw new Error("Request Failed!");
      }

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const token = data.data.token;
        setToken(token);
        setErrMsg(message);
        setOpen(true);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div class="login-box">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <div class="h1">
              <img
                src="/assets/img/nexxture-logo.png"
                alt="logo"
                width="150px"
              />
            </div>
          </div>
          <div class="card-body">
            <p class="login-box-msg">
              You forgot your password? Here you can easily reset new password.
            </p>
            <form autoComplete="off">
              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={userIdRef}
                  class="form-control"
                  placeholder="User ID"
                />
              </div>

              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={panNumberRef}
                  class="form-control"
                  placeholder="PAN Number"
                />
              </div>

              <div class="row">
                <button
                  type="button"
                  onClick={submitHandler}
                  class="btn btn-primary btn-block"
                >
                  Forgot Password
                </button>
              </div>

              <div class="row mt-2">
                <div class="col-12 mx-auto">
                  <p class="text-center">
                    <a href="/login">Back to Login</a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <OtpDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        errMsg={errMsg}
        token={token}
      />
    </React.Fragment>
  );
};

export default ForgotPassword;
