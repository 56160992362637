import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import DataTableComponent from "react-data-table-component";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import InvtTransferDialog from "./InvtTransferDialog";

const ActiveInvestments = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalAmountWithdrawn, setTotalAmountWithdrawn] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.activeInvestments(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const _data = responseData.data;
        setData(_data);

        let _totalAmount = 0;
        let _totalAmountWithdrawn = 0;

        _data.forEach((e) => {
          _totalAmount += e.amount;
          _totalAmountWithdrawn += e.amount_withdrawn;
        });

        setTotalAmount(_totalAmount);
        setTotalAmountWithdrawn(_totalAmountWithdrawn);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [expectedCommission, setExpectedCommission] = useState();

  const getExpectedCommission = async (order_id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.expectedCommission(
        token,
        order_id
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData.data;

        alert("₹" + data.commission_amount);

        // setExpectedCommission(data.commission_amount);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {row.tbl_user ? row.tbl_user.user_id : ""} (
            {row.tbl_user ? row.tbl_user.name : ""})
          </span>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    // {
    //   name: "Amount",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       <span style={{ color: "blue", fontSize: "16px", fontWeight: "bold" }}>
    //         ₹{row.amount}
    //       </span>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Amount Withdrawn",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       <span style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>
    //         ₹{row.amount_withdrawn}
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            class="badge badge-pill badge-primary"
            style={{ fontSize: "13px" }}
          >
            ₹{row.amount}
          </span>{" "}
        </div>
      ),
    },
    {
      name: "Withdrawn Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            class="badge badge-pill badge-danger"
            style={{ fontSize: "13px" }}
          >
            ₹{row.amount_withdrawn}
          </span>{" "}
        </div>
      ),
    },
    {
      name: "Lien Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            class="badge badge-pill badge-warning"
            style={{ fontSize: "13px" }}
          >
            ₹{row.lien_amount ? row.lien_amount : "0"}
          </span>{" "}
        </div>
      ),
    },
    {
      name: "Available Balance",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            class="badge badge-pill badge-success"
            style={{ fontSize: "13px" }}
          >
            ₹{parseFloat(row.amount - row.amount_withdrawn).toFixed(2)}
          </span>{" "}
        </div>
      ),
    },
    {
      name: "Expected Commission",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {expectedCommission ? (
            <span
              style={{ color: "blue", fontSize: "14px", fontWeight: "bold" }}
            >
              ₹{expectedCommission}
            </span>
          ) : (
            <span
              style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}
              onClick={() => {
                getExpectedCommission(row.order_id);
              }}
            >
              View
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Payment Mode",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_mode}</span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_date}</span>
        </div>
      ),
    },
    {
      name: "Cheque Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.cheque_status}</span>
        </div>
      ),
    },
    {
      name: "Payment Receipt",
      cell: (row) => (
        <div>
          {isLoading && (
            <span class="float-right badge badge-pill badge-danger">
              Fetching...
            </span>
          )}
          {!isLoading && (
            <span class="float-right">
              <span
                class="badge badge-pill badge-primary ml-2"
                onClick={() => {
                  viewPaymentReceiptHandler(row ? row.order_id : "");
                }}
              >
                Fetch
              </span>
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Investment Transfer",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <button
            className="btn btn-warning btn-sm"
            onClick={() => {
              setOpen(true);
              setSelectedData(row);
            }}
          >
            Transfer
          </button>
        </div>
      ),
    },
    {
      name: "Inv Receipt",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              //  downloadReceipt(row.order_id);

              window.open(
                "/admin/investment_receipt/" + row.id,
                "",
                "width=740,height=650,scrollbars=yes,resizable=1"
              );
              return true;
            }}
          >
            <DownloadIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const viewPaymentReceiptHandler = async (orderId) => {
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.viewPaymentReceipt(
        token,
        orderId
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const image = responseData.data.image;

        const newTab = window.open(image);

        newTab?.document.write(
          `<!DOCTYPE html><head><title>Image</title></head><body><img src="${image}"  ></body></html>`
        );

        newTab?.document.close();
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <div className="container-fluid" style={{ padding: "20px" }}>
        <DataTableComponent
          noHeader={true}
          columns={columns}
          data={data}
          progressPending={loading}
          highlightOnHover
        />

        <br />
        {data && (
          <div>
            <p style={{ color: "blue", fontSize: "16px", fontWeight: "bold" }}>
              Total Invested Amount: ₹{parseFloat(totalAmount).toFixed(2)}
            </p>
            <p style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>
              Total Withdrawn Amount: ₹
              {parseFloat(totalAmountWithdrawn).toFixed(2)}
            </p>
            <p style={{ color: "green", fontSize: "18px", fontWeight: "bold" }}>
              Available Balance: ₹
              {parseFloat(totalAmount - totalAmountWithdrawn).toFixed(2)}
            </p>
          </div>
        )}

        <div class="card-footer text-center">
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
          <button
            type="button"
            class="btn btn-default ml-5"
            onClick={() => {
              // history.push("/agent_list");
              window.close();
            }}
          >
            Close
          </button>
        </div>
      </div>

      <InvtTransferDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        selectedData={selectedData}
        fetchData={fetchData}
      />
    </React.Fragment>
  );
};

export default ActiveInvestments;
