import common from "../../utill/Common";

class CommissionWalletService {
  addTransaction = async (token, id, txn_type, amount, remark) => {
    const url = common.baseUrl + "admin/commission_wallet/add_transaction";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id, txn_type, amount, remark }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CommissionWalletService();
