import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AdminCashDepositService from "../../../services/Admin/AdminCashDepositService";
import PaymentService from "../../../services/Admin/PaymentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
// import AddCategoryDialog from "./AddCategoryDialog";

const AddPayment = () => {
  const loadingCtx = useContext(LoadingContext);

  const [banks, setBanks] = useState([]);

  React.useEffect(() => {
    fetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanks = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.fetchBanks(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setBanks(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [isAmountDisabled, setIsAmountDisabled] = useState(false);
  const [bankId, setBankId] = useState();
  const [paymentMode, setPaymentMode] = useState();
  const [file, setFile] = useState();

  const formRef = useRef();
  const amountRef = useRef();
  const paymentDateRef = useRef();
  const paymentRefIdRef = useRef();
  const remarkRef = useRef();

  const submitHandler = async () => {
    const amount = amountRef.current.value;
    const paymentDate = paymentDateRef.current.value;
    const paymentRefId = paymentRefIdRef.current.value;
    const remark = remarkRef.current.value;

    if (!bankId) {
      Swal.fire("Opps!", "Invalid Bank", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!paymentMode) {
      Swal.fire("Opps!", "Invalid Payment Mode", "error");
      return;
    }

    if (!paymentDate) {
      Swal.fire("Opps!", "Invalid Payment Date", "error");
      return;
    }

    if (!paymentRefIdRef) {
      Swal.fire("Opps!", "Invalid Payment REF ID", "error");
      return;
    }

    if (!file) {
      Swal.fire("Opps!", "Please upload payment receipt", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.add(
        token,
        bankId,
        amount,
        paymentMode,
        paymentDate,
        paymentRefId,
        file,
        remark
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const [amountInWord, setAmountInWord] = useState();

  const getWord = () => {
    const amount = amountRef.current.value;
    if (amount) {
      let words = toWords.convert(amount);
      setAmountInWord(words);
    } else {
      setAmountInWord();
    }
  };

  function handlePaymentReceipt(event) {
    let _file = event.target.files[0];

    const fileSizeKiloBytes = _file.size / 1024;

    if (fileSizeKiloBytes > 2048) {
      Swal.fire("Opps!", "The file size should be less than 2 MB", "error");
      return;
    }

    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

    if (!allowedExtensions.exec(_file.name)) {
      Swal.fire(
        "Opps!",
        "Invalid File Type (JPG, JPEG, PNG and PDF are allowed)",
        "error"
      );
      return;
    }

    setFile(_file);
  }

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Payment">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Add Payment</h3>
                    <div class="card-tools">
                      <a
                        href="/admin/payment_history"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Payment History
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-8 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Select Bank
                            </label>
                            <div class="col-sm-10">
                              <Autocomplete
                                options={banks}
                                getOptionLabel={(option) => option.bank_name}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Bank" />
                                )}
                                onChange={(e, v) => {
                                  setBankId(v ? v.id : "");
                                }}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Payment Mode
                            </label>
                            <div class="col-sm-10">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setPaymentMode(e.target.value);
                                  if (e.target.value == "CASH DEPOSIT") {
                                    setIsAmountDisabled(true);
                                  } else {
                                    setIsAmountDisabled(false);
                                  }
                                }}
                              >
                                <option value="">
                                  ---Select Payment Mode---
                                </option>
                                <option value="CASH DEPOSIT">
                                  CASH DEPOSIT
                                </option>
                                <option value="IMPS">IMPS</option>
                                <option value="NEFT">NEFT</option>
                                <option value="RTGS">RTGS</option>
                                <option value="UPI">UPI</option>
                                <option value="CHEQUE">CHEQUE</option>
                                <option value="TPT">TPT</option>
                                <option value="CREDIT CARD">CREDIT CARD</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Payment Date
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="date"
                                class="form-control"
                                ref={paymentDateRef}
                                //  disabled={isAmountDisabled}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Amount
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Amount"
                                maxLength={10}
                                ref={amountRef}
                                style={{ fontWeight: "bold" }}
                                onChange={getWord}
                                //   disabled={isAmountDisabled}
                              />
                              <p
                                style={{
                                  fontWeight: "bold",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                {amountInWord}
                              </p>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Payment Ref ID
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                name="name"
                                class="form-control"
                                placeholder="Payment Ref ID"
                                ref={paymentRefIdRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Payment Receipt
                            </label>
                            <div class="col-sm-10">
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    //  ref={paymentReceiptRef}
                                    onChange={handlePaymentReceipt}
                                    accept=".jpg,.jpeg,.png,application/pdf"
                                  />
                                  <label
                                    class="custom-file-label"
                                    for="exampleInputFile"
                                  >
                                    {file ? file.name : "Choose file"}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Remark
                            </label>
                            <div class="col-sm-10">
                              <textarea
                                class="form-control"
                                ref={remarkRef}
                              ></textarea>
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      {/* <AddCategoryDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        expenseCategoryList={expenseCategoryList}
      /> */}
    </React.Fragment>
  );
};

export default AddPayment;
