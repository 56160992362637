import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ToWords } from "to-words";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import PaymentService from "../../../services/Admin/PaymentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const PaymentHistory = () => {
  const loadingCtx = useContext(LoadingContext);

  const [banks, setBanks] = useState([]);

  React.useEffect(() => {
    fetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanks = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.fetchBanks(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setBanks(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [filterType, setFilterType] = useState("PAYMENT_DATE");

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [searchText, setSearchText] = useState("");
  const [paymentMode, setPaymentMode] = useState();
  const [crossCheckStatus, setCrossCheckStatus] = useState("");
  const [bankId, setBankId] = useState("");

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [userType, setUserType] = useState();
  const [totalAmount, setTotalAmount] = useState();

  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchData();
    getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, searchText, fromDate, toDate, filterType, bankId, paymentMode, crossCheckStatus

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    setUserType(_userType);
  };

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    PaymentService.history(
      token,
      filterType,
      fromDate,
      toDate,
      searchText,
      paymentMode,
      crossCheckStatus,
      bankId,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.data.result;
        const totalAmount = responseData.data.total_amount;
        setTotalAmount(totalAmount);
        setCount(responseData.data.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const history = useHistory();

  const columns = [
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Bank Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_bank ? row.tbl_bank.bank_name : "null"}</span>
        </div>
      ),
    },
    {
      name: "Payment Mode",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontWeight: "bold" }}>{row.payment_mode}</span> <br />
          <span>{row.payment_date}</span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "green" }}
          >
            ₹{row.amount}
          </span>
          <br />
          <span style={{ fontWeight: "bold", fontSize: "14px", color: "red" }}>
            {toWords.convert(row.amount)}
          </span>
        </div>
      ),
    },
    {
      name: "Payment REF ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_ref_id}</span>
        </div>
      ),
    },
    {
      name: "Linked Invt Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.linked_invt_order_id}</span>
        </div>
      ),
    },
    {
      name: "Cross Check Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.cross_check_status === "VERIFIED") {
              return (
                <span class="badge badge-pill badge-success">
                  {row.cross_check_status}
                </span>
              );
            } else if (row.cross_check_status === "PENDING") {
              return (
                <span class="badge badge-pill badge-warning">
                  {row.cross_check_status}
                </span>
              );
            } else if (row.cross_check_status === "ACTION_REQUIRED") {
              return (
                <span class="badge badge-pill badge-warning">
                  {row.cross_check_status}
                </span>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Timestamp",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/admin/edit_payment/" + row.id);
            }}
          >
            <AccountCircleIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              getPaymentReceiptLink(row.id);
            }}
          >
            <DownloadIcon />
          </IconButton>

          {userType === "Admin" && (
            <IconButton
              color="error"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure to delete?",
                  showCancelButton: true,
                  confirmButtonText: `Delete`,
                  confirmButtonColor: "red",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteHandler(row.id);
                  }
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const deleteHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.delete(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const getPaymentReceiptLink = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.viewPaymentReceipt(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        window.open(responseData.data.url, "_blank");

        //Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const xlsReport = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PaymentService.xlsReport(
        token,
        fromDate,
        toDate
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Payment History">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Payment History</h3>

                    <div class="card-tools">
                      <a
                        href="/admin/add_payment"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Add Payment
                      </a>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setFilterType(e.target.value);
                                    }}
                                  >
                                    <option value="PAYMENT_DATE" selected>
                                      PAYMENT_DATE
                                    </option>
                                    <option value="ENTRY_DATE">
                                      ENTRY_DATE
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="From Date"
                                    onChange={(e) => {
                                      setFromDate(e.target.value);
                                    }}
                                    defaultValue={fromDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="To Date"
                                    onChange={(e) => {
                                      setToDate(e.target.value);
                                    }}
                                    defaultValue={toDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <Autocomplete
                                    size="small"
                                    options={banks}
                                    getOptionLabel={(option) =>
                                      option.bank_name
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Bank"
                                      />
                                    )}
                                    onChange={(e, v) => {
                                      setBankId(v ? v.id : "");
                                    }}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setPaymentMode(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select Payment Mode---
                                    </option>
                                    <option value="CASH DEPOSIT">
                                      CASH DEPOSIT
                                    </option>
                                    <option value="IMPS">IMPS</option>
                                    <option value="NEFT">NEFT</option>
                                    <option value="RTGS">RTGS</option>
                                    <option value="UPI">UPI</option>
                                    <option value="CHEQUE">CHEQUE</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setCrossCheckStatus(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select Cross Check Status---
                                    </option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="VERIFIED">VERIFIED</option>
                                    <option value="ACTION_REQUIRED">
                                      ACTION_REQUIRED
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Text"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-3">
                                <button
                                  type="button"
                                  className="form-control btn-primary"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  Search
                                </button>
                              </div>

                              <div class="col-sm-3">
                                <div>
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary btn-icon waves-effect waves-light"
                                    onClick={xlsReport}
                                  >
                                    <i class="fa fa-download"></i> EXPORT
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);

                        fetchData();
                      }}
                    />

                    <br />
                    {data && (
                      <div>
                        <p>
                          Total Amount:{" "}
                          <b style={{ color: "green" }}>₹{totalAmount}</b>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default PaymentHistory;
