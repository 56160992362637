import common from "../../utill/Common";

class PaymentService {
  delete = async (token, id) => {
    const url = common.baseUrl + "admin/payment/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  add = async (
    token,
    bank_id,
    amount,
    payment_mode,
    payment_date,
    payment_ref_id,
    file,
    remark
  ) => {
    const url = common.baseUrl + "admin/payment/add";

    const dataArray = new FormData();
    dataArray.append("bank_id", bank_id);
    dataArray.append("amount", amount);
    dataArray.append("payment_mode", payment_mode);
    dataArray.append("payment_date", payment_date);
    dataArray.append("payment_ref_id", payment_ref_id);
    dataArray.append("payment_receipt", file);
    dataArray.append("remark", remark);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (
    token,
    id,
    bank_id,
    // amount,
    // payment_mode,
    // payment_date,
    payment_ref_id,
    remark
  ) => {
    const url = common.baseUrl + "admin/payment/edit";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        bank_id,
        // amount,
        // payment_mode,
        // payment_date,
        payment_ref_id,
        remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "admin/payment/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  viewPaymentReceipt = async (token, id) => {
    const url = common.baseUrl + "admin/payment/view_payment_receipt";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchBanks = async (token) => {
    const url = common.baseUrl + "admin/payment/banks";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  history = async (
    token,
    filter_type,
    from_date,
    to_date,
    search_text,
    payment_mode,
    cross_check_status,
    bank_id,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/payment/history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        filter_type,
        from_date,
        to_date,
        search_text,
        payment_mode,
        cross_check_status,
        bank_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  unlinkedPayments = async (token) => {
    const url = common.baseUrl + "admin/payment/unlinked_payments";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  xlsReport = async (token, from_date, to_date) => {
    const url = common.baseUrl + "admin/payment/xls_report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PaymentService();
