import common from "../../utill/Common";

class SignupService {
  verifyIntro = async (intro_id) => {
    const url = common.baseUrl + "user/signup/verify_intro";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        intro_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  sendOtp = async (mobile, email) => {
    const url = common.baseUrl + "user/signup/send_otp";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile,
        email,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  verifyOtp = async (
    token,
    otp,
    intro_id,
    pan_number,
    title,
    name,
    dob,
    gender,
    fathers_name,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address,
    nominee_name,
    nominee_relation,
    beneficiary_name,
    bank_name,
    account_number,
    ifsc_code,
    account_type,
    password,
    pin
  ) => {
    const url = common.baseUrl + "user/signup/verify_otp";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        otp,
        intro_id,
        pan_number,
        title,
        name,
        dob,
        gender,
        fathers_name,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
        nominee_name,
        nominee_relation,
        beneficiary_name,
        bank_name,
        account_number,
        ifsc_code,
        account_type,
        password,
        pin,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SignupService();
